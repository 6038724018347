import 'slick-carousel'

function smooth_scroll(link, e) {
  if (e) e.preventDefault()
  var hash = false
  if (!link && window.location.hash) {
    hash = window.location.hash
  } else if (link && link.indexOf('#') == 0) {
    hash = link
  } else if (
    link &&
    location.pathname.replace(/^\//, '') == link.pathname.replace(/^\//, '') &&
    location.hostname == link.hostname
  ) {
    hash = link.hash
  }
  if (hash) {
    $('html, body').animate(
      {
        scrollTop: $(hash).offset().top,
      },
      600
    )
    history.replaceState(null, null, hash)
  }
  $(window).trigger('hashchange')
}

let Menu = {
  el: {
    ham: $('div.menu'),
    menuTop: $('.menu-top'),
    menuMiddle: $('.menu-middle'),
    menuBottom: $('.menu-bottom'),
  },

  init: function() {
    Menu.bindUIactions()
  },

  bindUIactions: function() {
    Menu.el.ham.on('click', function(event) {
      Menu.activateMenu(event)
      event.preventDefault()
      $('.banner .navbar ul.nav').fadeToggle()
    })
  },

  activateMenu: function() {
    Menu.el.menuTop.toggleClass('menu-top-click')
    Menu.el.menuMiddle.toggleClass('menu-middle-click')
    Menu.el.menuBottom.toggleClass('menu-bottom-click')
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages

    /* eslint-disable */
    $(
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-tabs], [data-vc-accordion])'
    ).on('click', function(e) {
      smooth_scroll($(this).attr('href'), e)
    })
    /* eslint-enable */
    if (window.location.hash) {
      smooth_scroll(window.location.hash)
    }

    Menu.init()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    if (!$('body').hasClass('home')) {
      let count = $('.secondary-menu ul.sub-menu:visible').length
      if (count == 0) {
        $('.secondary-menu').css('marginBottom', '100px')
      } else if (count == 1) {
        $('.secondary-menu').css('marginBottom', '140px')
      } else if (count == 2) {
        $('.secondary-menu').css('marginBottom', '190px')
      } else {
        $('.secondary-menu').css('marginBottom', '190px')
      }
    }

    $('.header-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: false,
      cssEase: 'linear',
    })

    $('ul.nav .menu-item-has-children')
      .mouseenter(function() {
        $(this)
          .find('ul.dropdown-menu')
          .show()
      })
      .mouseleave(function() {
        $(this)
          .find('> ul.dropdown-menu')
          .hide()
      })
  },
}
